import React, { Component } from "react";
import { Route, Routes } from "react-router";
import { HashRouter, BrowserRouter as Router } from "react-router-dom";
import Loadable from "react-loadable";
import Layout from "../components/layout";

import {
  Home,
  Activity,
  PaperPresentation,
  ProjectModel,
  GuestLectures,
  ImplantTraining,
  IndustrialVisit,
  SportsAndGames,
  NCC,
  ExtensionServices,
  Placement,
  JournalAndBookPublication,
  Grants,
  Patent,
  Semester,
  SymposiumExpo,
  WomenInDevelopment,
  FacultyDevelopment,
  Consultant,
  NSS,
  AlumniEvent,
  Miscellaneous,
  Awards,
  Events,
  Attendance,
  Advertisements,
  PressReports,
  MOUs,
  UpcomingEvents,
} from "../../screens/";
import FacultyDetails from "../../screens/facultyDetailsDash";
//import AdmissionDetails from "../../screens/admissionDetailsDash";
import StudentDetails from "../../screens/studentDetailsDash";
import AluminiDetails from "../../screens/aluminiDetailsDash";
import SubjectDetails from "../../screens/subjectDetailsDash";
import DepartmentDetails from "../../screens/departmentDetailsDash";
import RoleDetails from "../../screens/roleDetailsDash";
import RoleActivityDetails from "../../screens/roleActivityMapping";
import BatchDetails from "../../screens/batchDetailsDash";
import FormRoleDetails from "../../screens/formroleDetailsDash";
import BatchStudMappings from "../../screens/batchStudentMapping";
//import DeptStudMappings from "../../screens/deptStudentMapping";
import BatchSubMappings from "../../screens/batchSubjectMapping";
import AttendancePage from "../../screens/activity/attendance";
import FDP from "../../screens/fdp";
import ODP from "../../screens/odpForm";
import BPE from '../../screens/bpeForm';

import ReportPage from "../../screens/activity/reports";
import NewItem from "../components/item";
import AttendanceReports from "../../screens/attendanceReports";
import StockReports from "../../screens/stockReports";
import Indent from "../../screens/indent";
import Inventory from "../../screens/inventoryDetails";
import InventorySpec from "../../screens/inventorySpecDetails";
import StockInventory from "../../screens/stockInventoryDetails";
import PurchasedOrderDetails from "../../screens/purchasedOrderDetails";
import InventoryIssuedDetails from "../../screens/inventoryIssuedDetails";
//import StockReports from "../../screens/stockReports";
import LabDetails from "../../screens/labDetails";
import HOADetails from "../../screens/hoaDetails";
import BudgetDetails from "../../screens/budgetDetailsDash";
import QuatationFile from '../../screens/indent/documentViewer';
import MarkReports from '../../screens/markReports';
import FacultySubjectMapping from '../../screens/facultySubjectMapping';
import CollegeAnnouncement from '../../screens/announcement';
import UpdateStudentSemDate from '../../screens/updateStudentSemDateDetails';
import SendFeedBack from '../../screens/sendFeedbackDetails';
import FeedbackReport from '../../screens/feedbackReports';
import MemberDetails from "../../screens/OtherMemberDetails";
 
function Loading({ error }) {
  if (error) {
    return "Oh nooess!";
  } else {
    return <h3>Loading...</h3>;
  }
}
export const Path = {
  root: "/",
  welcome: "/welcome",
  home: "/home",
  productdetails: "/productdetails",
  salesdetails: "/sales",
  //checkout: "/checkout",
  myorders: "/myorders",
  product: "/newitem",
  login: "/login",
  activity: "/activity",
  paperpresentation: "/paperpresentation",
  projectmodel: "/projectmodel",
  guestlectures: "/guestlectures",
  implanttraining: "/implanttraining",
  industrialvisit: "/industrialvisit",
  sportsandgames: "/sportsandgames",
  ncc: "/ncc",
  extensionservices: "/extensionservices",
  placement: "/placement",
  journalandbookpublication: "/journalandbookpublication",
  grants: "/grants",
  alumnievent: "/alumnievent",
  patent: "/patent",
  semester: "/semester",
  symposiumexpo: "/symposiumexpo",
  consultant: "/consultant",
  womenindevelopment: "/womenindevelopment",
  facultydevelopment: "/facultydevelopment",
  nss: "/nss",
  facultyDetails: "/facultyDetails",
  //admissionDetails: "/admissionDetails",
  studentDetails: "/studentDetails",
  aluminiDetails: "/aluminidetails",
  miscellaneous: "/miscellaneous",
  subjectDetails: "/subjectDetails",
  awards: "/awards",
  events: "/events",
  advertisements: "/advertisements",
  pressreports: "/pressreports",
  mous: "/mous",
  roleDetails: "/roleDetails",
  budgetDetails: "/budgetDetails",
  departmentDetails: "/departmentDetails",
  roleActivity: "/roleActivity",
  batchDetails: "/batchDetails",
  formRoleDetails: "/formRoleDetails",
  batchStudMapping: "batchStudMapping",
  //deptStudMapping: "deptStudMapping",
  batchSubMapping: "/batchSubMapping",
  attendance: "/attendance",
  reports: "/reports",
  attendanceReports: "/attendanceReports",
  stockReports:"/stockReports",
  indent: "/indent",
  FDP: "/FDP",
  ODP:"/ODP",
  inventory: "/inventory",
  inventoryspec: "/inventoryspec",
  purchasedorder: "/purchasedorder",
  stockinventory: "/stockinventory",
  inventoryissueddetails: "/inventoryissueddetails",
  //stockreport: "/stockreport",
  labdetails: "/labdetails",
  hoadetails: "/hoadetails",
  quatationFile:"/quatationFile",
  BPE:"/BPE",
  markreports:"/markreports",
  facultysubjectmapping:"/facultysubjectmapping",
  announcement:"/announcement",
  updateStudentSemDate:"/updateStudentSemDate",
feedbackReport :"/feedbackReport",
  sendFeedback:"/sendFeedback",
  upcomingevents: "/upcomingevents",
  memberDetails:"/memberDetails"

};

const Welcome = Loadable({
  loader: () => import("../../screens/welcome"),
  loading: Loading,
});

const Login = Loadable({
  loader: () => import("../../screens/login"),
  loading: Loading,
});

// history={history}
class AppStackRoot extends Component {
  render() {
    console.log("rendering Layout");
    return (
      <Router>
        <Layout>
          <Routes>
            <Route exact path={Path.root} element={<Home />} />
            <Route exact path={Path.login} element={<Login />} />
            <Route exact path={Path.activity} element={<Activity />} />
            <Route exact path={Path.welcome} element={<Welcome />} />
            <Route
              exact
              path={Path.paperpresentation}
              element={<PaperPresentation />}
            />
            <Route exact path={Path.projectmodel} element={<ProjectModel />} />
            <Route
              exact
              path={Path.guestlectures}
              element={<GuestLectures />}
            />
            <Route
              exact
              path={Path.implanttraining}
              element={<ImplantTraining />}
            />
            <Route
              exact
              path={Path.industrialvisit}
              element={<IndustrialVisit />}
            />
            <Route
              exact
              path={Path.sportsandgames}
              element={<SportsAndGames />}
            />
            <Route
              exact
              path={Path.facultyDetails}
              element={<FacultyDetails />}
            />
            {/* <Route
              exact
              path={Path.admissionDetails}
              element={<AdmissionDetails />}
            /> */}
            <Route exact path={Path.ncc} element={<NCC />} />
            <Route
              exact
              path={Path.extensionservices}
              element={<ExtensionServices />}
            />
            <Route exact path={Path.placement} element={<Placement />} />
            <Route
              exact
              path={Path.journalandbookpublication}
              element={<JournalAndBookPublication />}
            />
            <Route exact path={Path.grants} element={<Grants />} />
            <Route exact path={Path.alumnievent} element={<AlumniEvent />} />
            <Route exact path={Path.patent} element={<Patent />} />
            <Route exact path={Path.semester} element={<Semester />} />
            <Route
              exact
              path={Path.symposiumexpo}
              element={<SymposiumExpo />}
            />
            <Route
              exact
              path={Path.womenindevelopment}
              element={<WomenInDevelopment />}
            />
            <Route
              exact
              path={Path.facultydevelopment}
              element={<FacultyDevelopment />}
            />
            <Route exact path={Path.consultant} element={<Consultant />} />
            <Route exact path={Path.nss} element={<NSS />} />
            <Route
              exact
              path={Path.studentDetails}
              element={<StudentDetails />}
            />

            <Route
              exact
              path={Path.aluminiDetails}
              element={<AluminiDetails />}
            />
            <Route
              exact
              path={Path.miscellaneous}
              element={<Miscellaneous />}
            />
            <Route exact path={Path.awards} element={<Awards />} />
            <Route exact path={Path.events} element={<Events />} />
            <Route exact path={Path.advertisements} element={<Advertisements />} />
            <Route exact path={Path.pressreports} element={<PressReports />} />
            <Route exact path={Path.mous} element={<MOUs />} />
            <Route exact path={Path.upcomingevents} element={<UpcomingEvents />} />

            <Route
              exact
              path={Path.subjectDetails}
              element={<SubjectDetails />}
            />
            <Route
              exact
              path={Path.departmentDetails}
              element={<DepartmentDetails />}
            />
            <Route exact path={Path.roleDetails} element={<RoleDetails />} />
            <Route
              exact
              path={Path.roleActivity}
              element={<RoleActivityDetails />}
            />
            <Route exact path={Path.batchDetails} element={<BatchDetails />} />
            <Route exact path={Path.formRoleDetails} element={<FormRoleDetails />} />
            <Route
              exact
              path={Path.batchStudMapping}
              element={<BatchStudMappings />}
            />
            {/* <Route
              exact
              path={Path.deptStudMapping}
              element={<DeptStudMappings />}
            /> */}
            <Route
              exact
              path={Path.batchSubMapping}
              element={<BatchSubMappings />}
            />
            <Route exact path={Path.attendance} element={<AttendancePage />} />
            <Route exact path={Path.reports} element={<ReportPage />} />
            <Route exact path={Path.attendanceReports} element={<AttendanceReports />} />
            <Route exact path={Path.indent} element={<Indent />} />
            <Route exact path={Path.FDP} element={<FDP />} />
            <Route exact path={Path.ODP} element={<ODP />} />
            <Route exact path={Path.BPE} element={<BPE />} />
            <Route exact path={Path.markreports} element={<MarkReports />} />

            <Route exact path={Path.inventory} element={<Inventory />} />

            <Route exact path={Path.inventoryspec} element={<InventorySpec />} />

            <Route exact path={Path.purchasedorder} element={<PurchasedOrderDetails />} />

            <Route exact path={Path.stockinventory} element={<StockInventory />} />

            <Route exact path={Path.inventoryissueddetails} element={<InventoryIssuedDetails />} />

            {/* <Route exact path={Path.stockreport} element={<StockReports />} /> */}

            <Route exact path={Path.labdetails} element={<LabDetails />} />

            <Route exact path={Path.hoadetails} element={<HOADetails />} />
            <Route exact path={Path.budgetDetails} element={<BudgetDetails />} />
            
            <Route exact path={Path.stockReports} element={<StockReports />} />
            <Route exact path={Path.quatationFile} element={<QuatationFile />} />
            <Route exact path={Path.facultysubjectmapping} element={<FacultySubjectMapping />} />
            <Route exact path={Path.updateStudentSemDate} element={<UpdateStudentSemDate />} />
            <Route exact path={Path.announcement} element={<CollegeAnnouncement />} />
            <Route exact path={Path.feedbackReport} element={<FeedbackReport />} />
            <Route exact path={Path.memberDetails} element={<MemberDetails />} />


            <Route exact path={Path.sendFeedback} element={<SendFeedBack />} />
            {/*<Route exact path={Path.consultant} element={<Consultant/>} />
              <Route exact path={Path.fdpactivity} element={<FDPActivity/>} />
              <Route exact path={Path.facultydetails} element={<FacultyDetails/>} />
              <Route exact path={Path.studentdetails} element={<StudentDetails/>} />
    <Route exact path={Path.alumnidetails} element={<AlumniDetails/>} />*/}

            {/*              <Route exact path={Path.home} element={<Home/>} />
              <Route exact path={Path.productdetails} element={<ProductDetails/>} />
              <Route exact path={Path.salesdetails} element={<SalesDetails/>} />
              <Route exact path={Path.checkout} element={<Checkout/>} />
              <Route exact path={Path.myorders} element={<MyOrders/>} />
              <Route exact path={Path.product} element={<NewItem/>} /> */}
          </Routes>
        </Layout>
        {/* <PrivateRoute path={Path.performtask} permission={SCREENS.SERVICE_REQUEST} component={PerformTasks} /> */}
      </Router>
    );
  }
}

export default AppStackRoot;
