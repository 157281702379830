import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import './bootstrap.css';
// import './bootstrap.min.css';
// import './styles.css';

import "./addnew.css";
//import logo from "../../app/assets/images/logo.png";
import logo from "../../app/assets/images/LogoLates.png";

import {
  loginsSelector,
  validateLogin,
} from "../../app/redux/slices/loginSlice";
import { Form, FormLabel } from "react-bootstrap";

const Login = () => {
  // set up dispatch
  const dispatch = useDispatch();
  //const history=useHistory();
  const navigate = useNavigate();
  // fetch data from our store
  const { errorMSG, successMsg } = useSelector((state) => state.login);

  console.log("login selector", loginsSelector);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [userNameErrorMsg, setUserNameErrorMsg] = useState("");
  const [pswdErrorMsg, setPswdErrorMsg] = useState("");

  const onClickLogin = () => {
    console.log(username, password);
    try {
      setUserNameErrorMsg("");
      setPswdErrorMsg("");
      const error = validationFun();
      if (error) {
        dispatch(validateLogin(username, password)).then(() => {
          console.log("success message just before navigation", successMsg);
          successMsg.length > 0 && navigate("/Welcome");
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    console.log("success message", successMsg);
    const getUser = JSON.parse(localStorage.getItem("user"));
    console.log(getUser);
    getUser && navigate("/Welcome"); //history.push('/Welcome')
  }, [successMsg]);

  const validationFun = () => {
    let isValid = true;
    if (!username) {
      setUserNameErrorMsg("Username is required");
      isValid = false;
    }

    if (!password) {
      setPswdErrorMsg("Password is required");
      isValid = false;
    }

    return isValid;
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "userName":
        setUserName(e.target.value);
        console.log(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
    }
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  // render the items
  const renderItems = () => {
    // loading state
    //   if (loading) return <strong>Loading please wait...</strong>;

    //   // error state
    //   if (error) return <strong>Items not available at this time</strong>;
    // regular data workflow
    return (
      <div className="container">
        <div className="login-logo off-canvas">
          {" "}
          <a href="#">
            <img src={logo} width="340" />
          </a>{" "}
        </div>

        {/* <label htmlFor="Password" className="fw-bold">
          {errorMSG}
        </label> */}
        <div className="form-container off-canvas">
          <Form>
            <h2 className="text-center">Login</h2>
            <Form.Group>
              <label htmlFor="EmailAddress" className="fw-bold">
                User Name
              </label>
              <Form.Control
                type="text"
                name="username"
                id="userName"
                onChange={onTextValueChange}
                value={username}
                aria-required="true"
                aria-invalid="true"
                required
                onKeyDown={handleEnter}
              />
            </Form.Group>
            {userNameErrorMsg ? (
              <h6 style={{ color: "red" }}>{userNameErrorMsg}</h6>
            ) : null}
            <p></p>
            <Form.Group>
              <label htmlFor="Password" className="fw-bold">
                Password
              </label>
              <Form.Control
                type="password"
                name="password"
                id="password"
                onChange={onTextValueChange}
                value={password}
                aria-required="true"
                aria-invalid="true"
                required
                onKeyDown={handleEnter}
              />
            </Form.Group>
            {pswdErrorMsg ? (
              <h6 style={{ color: "red" }}>{pswdErrorMsg}</h6>
            ) : null}
            <br></br>
            {errorMSG ? (
              <h6
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {errorMSG}
              </h6>
            ) : null}
            <p className="text-center">
              <button
                type="button"
                className="btn btn-lg btn-primary btn-block form-roundedbtn"
                onClick={() => {
                  onClickLogin();
                }}
                onFocus={() => {
                  onClickLogin();
                }}
              >
                Sign in
              </button>
            </p>
          </Form>
        </div>
      </div>
    );
  };

  // template
  return <div>{renderItems()}</div>;
};

export default Login;
